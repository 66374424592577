import { render, staticRenderFns } from "./Order3View.vue?vue&type=template&id=daba234c&scoped=true&"
import script from "./Order3View.vue?vue&type=script&lang=js&"
export * from "./Order3View.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daba234c",
  null
  
)

export default component.exports