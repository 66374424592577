<template>
  <b-card>
    <FormView
      :data="$store.getters['order/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Order Created',
          type: 'datetime',
        },
        {
          key: 'code',
          label: 'Order Number',
        },
        {
          key: 'price',
          label: 'Amount',
          type: 'gold',
        },
        {
          key: 'service.name',
          label: 'Service',
          type: 'profile',
          image: 'service.media[0].thumbUrl',
          url: {
            callback(data) {
              return `/service/${data.service.id}`
            },
          },
        },
        {
          key: 'paymentMethod',
          label: 'Payment method',
          type: 'badge',
          map: {
            paypal: 'paypal',
            dragonpay_gcash: 'gcash',
            stripe_paynow: 'paynow',
            gold: 'warning',
            stripe: 'stripe',
            senangpay: 'senangpay',
          },
        },
        {
          key: 'status',
          label: 'Status',
          type: 'dot',
          sortable: false,
          map: {
            completed: 'success',
            started: 'success',
            start_pending: 'warning',
            start_cancelled: 'danger',
            cancelled: 'danger',
          },
        },
        {
          key: 'updatedAt',
          label: 'Order Updated',
          type: 'datetime',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('order/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
